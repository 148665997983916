import { isLeft } from 'fp-ts/Either'

import {
  ContractState,
  GetSignedTokenRequestC,
  GetSignedTokenResponseC,
} from 'src/models/api'
import { api } from 'src/utils/api'

export const getSignedToken = async (account: string) => {
  const response = await api.post(
    'signed-token',
    GetSignedTokenRequestC.encode({ walletAddress: account })
  )
  const decodedResponse = GetSignedTokenResponseC.decode(response.data)

  if (isLeft(decodedResponse)) {
    throw Error()
  }

  if (decodedResponse.right.status === ContractState.Pending) {
    throw Error('The sale has not started')
  }

  if (decodedResponse.right.status === ContractState.PreSale) {
    const data = decodedResponse.right.result
    if (data.type === 'error') {
      throw Error(data.error)
    }

    return { salt: data.salt, signedToken: data.signedToken }
  }

  if (decodedResponse.right.status === ContractState.Public) {
    return decodedResponse.right.result
  }

  if (decodedResponse.right.status === ContractState.Finished) {
    throw Error('All boxes have been minted')
  }

  throw Error()
}

export const formatAccount = (account: string | null | undefined) =>
  account
    ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
    : ''
