import css from '@emotion/css'
import styled from '@emotion/styled'
import { motion, MotionProps } from 'framer-motion'

import IconButton from '@mui/material/IconButton'

const Button = styled(IconButton)(
  ({ theme }) => css`
    padding: 2px;
    z-index: 10001;
    background-color: transparent;
    margin-left: ${theme.spacing(3)};

    &:hover {
      background-color: transparent;
    }

    svg {
      width: 80px;
    }

    ${theme.breakpoints.down('sm')} {
      width: 60px;
      margin-left: 0;
    }
  `
)

interface Props {
  burgerToggle: () => void
}

const Path = (props: MotionProps & { d?: string }) => (
  <motion.path {...props} stroke="#fff" strokeWidth="2" fill="transparent" />
)

const Burger: React.FC<Props> = ({ burgerToggle }) => (
  <Button onClick={burgerToggle} aria-label="Menu" disableRipple>
    <svg viewBox="0 0 30 28">
      <Path
        variants={{
          open: { d: 'M 10 8 L 22 20' },
          closed: { d: 'M 5 12 L 29 12' },
        }}
      />
      <Path
        variants={{
          open: { d: 'M 10 20 L 22 8' },
          closed: { d: 'M 2 18 L 26 18' },
        }}
      />
    </svg>
  </Button>
)

export default Burger
