import css from '@emotion/css'
import styled from '@emotion/styled'

import { Alert, Snackbar } from '@mui/material'

import { useToast } from 'src/hooks/store/useToast'

const StyledAlert = styled(Alert)(
  ({ theme, severity }) => css`
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    background-color: ${severity === 'error'
      ? theme.palette.error.main
      : theme.palette.success.main};

    .MuiAlert-icon {
      color: #fff;
    }

    ${theme.breakpoints.down('sm')} {
      max-width: 95%;
    }
  `
)

const Toast: React.FC = () => {
  const { message, severity, isToastOpen, closeToast } = useToast()

  return (
    <Snackbar
      open={isToastOpen}
      onClose={closeToast}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <StyledAlert onClose={closeToast} severity={severity}>
        {message}
      </StyledAlert>
    </Snackbar>
  )
}

export default Toast
