import { ethers } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

export const RPC_URL = process.env.REACT_APP_RPC_URL as string
export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID as string)

export enum Chains {
  Mainnet = 'mainnet',
  Ropsten = 'ropsten',
  Rinkeby = 'rinkeby',
  Localhost = 'localhost',
}

export const ChainIdToName: Record<number, Chains> = {
  1: Chains.Mainnet,
  3: Chains.Ropsten,
  4: Chains.Rinkeby,
  31337: Chains.Localhost,
}

export const CHAIN_NAME = ChainIdToName[CHAIN_ID]

export const POLLING_INTERVAL = 12000

const CONNECTOR_STORAGE_KEY = 'connector'

export const getConnectorName = () => {
  const connector = localStorage.getItem(CONNECTOR_STORAGE_KEY)

  return connector ? (connector as ConnectorNames) : null
}

export const setConnectorToStorage = (connectorName: ConnectorNames) => {
  localStorage.setItem(CONNECTOR_STORAGE_KEY, connectorName)
}

export const removeConnectorFromStorage = () => {
  localStorage.removeItem(CONNECTOR_STORAGE_KEY)
}

export enum ConnectorNames {
  Coinbase = 'Coinbase',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
}

type Connectors = Record<ConnectorNames, AbstractConnector>

export const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL

  return library
}

const metamask = new InjectedConnector({
  supportedChainIds: [CHAIN_ID],
})

const walletconnect = new WalletConnectConnector({
  qrcode: true,
  rpc: { [CHAIN_ID]: RPC_URL },
  pollingInterval: POLLING_INTERVAL,
  bridge: 'https://bridge.walletconnect.org',
})

const coinbase = new WalletLinkConnector({
  url: RPC_URL,
  appName: 'AIFA',
})

export const connectors: Connectors = {
  [ConnectorNames.MetaMask]: metamask,
  [ConnectorNames.Coinbase]: coinbase,
  [ConnectorNames.WalletConnect]: walletconnect,
}

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL)
