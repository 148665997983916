import css from '@emotion/css'
import styled from '@emotion/styled'

import { Box, AppBar, Toolbar, Container } from '@mui/material'

import { Routes } from 'src/utils/routes'
import Account from 'src/modules/header/Account'
import BurgerMenu from 'src/modules/header/burger'
import { useBreakpoints } from 'src/hooks/useBreakpoints'
import { HeaderLink } from 'src/modules/header/HeaderLink'

import { ReactComponent as Logo } from 'src/assets/images/aifa-logo.svg'

const StyledAppBar = styled(AppBar)`
  background-color: transparent;
`

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => css`
    padding: 0 !important;
    height: ${theme.spacing(12)};
  `
)

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const AifaLogo = styled(Logo)(
  ({ theme }) => css`
    width: 120px;
    z-index: 10001;

    path {
      fill: #fff;
    }

    ${theme.breakpoints.down('sm')} {
      width: 90px;
    }
  `
)

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

const routesConfig = [
  { route: Routes.Home, label: 'Home', exact: true },
  { route: Routes.About, label: 'About', exact: true },
  { route: Routes.Purchase, label: 'Purchase', exact: true },
  // TODO: restore in phase 2
  // { route: Routes.Dashboard, label: 'My Dashboard', exact: false },
]

const Header: React.FC = () => {
  const { isSmallScreen, showBurgerMenu } = useBreakpoints()

  return (
    <StyledAppBar position="static" elevation={0}>
      <StyledToolbar>
        <StyledContainer>
          <AifaLogo />
          {!showBurgerMenu && (
            <StyledNav>
              {routesConfig.map(({ exact, route, label }) => (
                <HeaderLink
                  to={route}
                  key={route}
                  exact={exact}
                  activeClassName="active-link"
                >
                  {label}
                </HeaderLink>
              ))}
            </StyledNav>
          )}
          <Box display="flex" alignItems="center">
            {!isSmallScreen && <Account />}
            {showBurgerMenu && (
              <BurgerMenu routes={routesConfig} isSmallScreen={isSmallScreen} />
            )}
          </Box>
        </StyledContainer>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Header
