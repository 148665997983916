import css from '@emotion/css'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import Background from 'src/components/layout/Background'

import { ReactComponent as Shield } from 'src/assets/images/shield-logo.svg'

const StyledBackground = styled(Background)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ShieldContainer = styled(motion.div)(
  ({ theme }) => css`
    width: 110px;
    margin-top: -${theme.spacing(12)};
  `
)

const transition = {
  duration: 2,
  loop: Infinity,
  ease: 'easeInOut',
}

const animate = { scale: [0.8, 1, 0.8], opacity: [0.4, 1, 0.4] }

const PageLoading: React.FC = () => (
  <StyledBackground>
    <ShieldContainer animate={animate} transition={transition}>
      <Shield width="100%" />
    </ShieldContainer>
  </StyledBackground>
)

export default PageLoading
