import css from '@emotion/css'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

import { BURGER_MENU_BREAKPOINT } from 'src/utils/constants'

export const HeaderLink = styled(NavLink)(
  ({ theme }) => css`
    color: #fff;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    text-transform: uppercase;
    margin-right: ${theme.spacing(10)};
    font-size: ${theme.typography.pxToRem(16)};
    line-height: ${theme.typography.pxToRem(22)};
    font-family: ${theme.typography.fontFamilySecondary};

    &:last-child {
      margin-right: 0;
    }

    &.active-link {
      color: ${theme.palette.text.mid};
    }

    ::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      height: 2px;
      position: absolute;
      transform: scaleX(0);
      background-color: currentColor;
      transform-origin: bottom right;
      transition: transform 0.3s ease;
    }

    @media (hover: hover) {
      :hover {
        color: #fff;

        ::before {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }

      &.active-link {
        color: ${theme.palette.text.mid};
      }
    }

    ${theme.breakpoints.down('lg')} {
      margin-right: ${theme.spacing(8)};
    }

    ${theme.breakpoints.down(BURGER_MENU_BREAKPOINT)} {
      margin: ${theme.spacing(0, 2.25, 4, 0)};
      font-size: ${theme.typography.pxToRem(32)};
      line-height: ${theme.typography.pxToRem(32)};

      &:last-child {
        margin-right: ${theme.spacing(2.25)};
      }
    }

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(0, 2.25, 3, 0)};

      &:last-child {
        margin-right: ${theme.spacing(2.25)};
      }
    }

    ${theme.breakpoints.down(380)} {
      font-size: ${theme.typography.pxToRem(24)};
      line-height: ${theme.typography.pxToRem(24)};
    }
  `
)
