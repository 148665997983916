import css from '@emotion/css'

const colGap = (spacing: string) => css`
  > .MuiTypography-root,
  > * {
    margin-bottom: ${spacing};
  }
  > :last-child {
    margin-bottom: 0px;
  }
`

const rowGap = (spacing: string) => css`
  > .MuiTypography-root,
  > * {
    margin-right: ${spacing};
  }
  > :last-child {
    margin-right: 0px;
  }
`

const gap = {
  col: colGap,
  row: rowGap,
}

export default gap
