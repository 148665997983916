import css from '@emotion/css'
import styled from '@emotion/styled'

import { Container, Typography } from '@mui/material'

import asmLogoSrc from 'src/assets/images/asm-logo.png'

const StyledFooter = styled.footer(
  ({ theme }) => css`
    background-color: ${theme.palette.background.teal};
  `
)

const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${theme.spacing(10, 0)};

    img {
      width: 130px;
      margin-right: ${theme.spacing(3)};
    }

    p {
      text-align: right;
    }

    ${theme.breakpoints.down('md')} {
      margin: ${theme.spacing(6, 0)};
    }

    ${theme.breakpoints.down('sm')} {
      align-items: center;
      flex-direction: column;
      margin: ${theme.spacing(3, 0)};

      img {
        margin: ${theme.spacing(0, 0, 3)};
      }

      p {
        text-align: center;
      }
    }
  `
)

const Divider = styled.hr(
  ({ theme }) => css`
    border: 1px solid transparent;
    border-top: 1px solid ${theme.palette.secondary.main};
    opacity: 50%;
    height: 0px;
    margin: 0px;
    margin-top: -1px;
    padding: 0px;
    background-color: transparent;
  `
)

const year = new Date().getFullYear()

const Footer: React.FC = () => (
  <StyledFooter>
    <Container>
      <Divider />
      <Content>
        <img src={asmLogoSrc} alt="Altered State Machine logo" />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontWeight: 500 }}
        >
          &copy; Copyright Altered State Machine Ltd {year}, All Rights Reserved
        </Typography>
      </Content>
    </Container>
  </StyledFooter>
)

export default Footer
