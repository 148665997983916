import create from 'zustand'

interface UseWalletsDialogState {
  isWalletsDialogOpen: boolean
  openWalletsDialog: () => void
  closeWalletsDialog: () => void
}

export const useWalletsDialog = create<UseWalletsDialogState>(set => ({
  isWalletsDialogOpen: false,
  openWalletsDialog: () => set({ isWalletsDialogOpen: true }),
  closeWalletsDialog: () => set({ isWalletsDialogOpen: false }),
}))
