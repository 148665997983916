import gilroyThinWoff from 'src/assets/fonts/Gilroy/Gilroy-Thin.woff'
import gilroyBoldWoff from 'src/assets/fonts/Gilroy/Gilroy-Bold.woff'
import gilroyLightWoff from 'src/assets/fonts/Gilroy/Gilroy-Light.woff'
import gilroyMediumWoff from 'src/assets/fonts/Gilroy/Gilroy-Medium.woff'
import gilroyRegularWoff from 'src/assets/fonts/Gilroy/Gilroy-Regular.woff'
import gilroyRegularItalicWoff from 'src/assets/fonts/Gilroy/Gilroy-RegularItalic.woff'

import gilroyThinWoff2 from 'src/assets/fonts/Gilroy/Gilroy-Thin.woff2'
import gilroyBoldWoff2 from 'src/assets/fonts/Gilroy/Gilroy-Bold.woff2'
import gilroyLightWoff2 from 'src/assets/fonts/Gilroy/Gilroy-Light.woff2'
import gilroyMediumWoff2 from 'src/assets/fonts/Gilroy/Gilroy-Medium.woff2'
import gilroyRegularWoff2 from 'src/assets/fonts/Gilroy/Gilroy-Regular.woff2'
import gilroyRegularItalicWoff2 from 'src/assets/fonts/Gilroy/Gilroy-RegularItalic.woff2'

import monumentExtendedBoldWoff from 'src/assets/fonts/MonumentExtended/MonumentExtended-Bold.woff'
import monumentExtendedLightWoff from 'src/assets/fonts/MonumentExtended/MonumentExtended-Light.woff'
import monumentExtendedRegularWoff from 'src/assets/fonts/MonumentExtended/MonumentExtended-Regular.woff'
import monumentExtendedUltralightWoff from 'src/assets/fonts/MonumentExtended/MonumentExtended-Ultralight.woff'

import monumentExtendedBoldWoff2 from 'src/assets/fonts/MonumentExtended/MonumentExtended-Bold.woff2'
import monumentExtendedLightWoff2 from 'src/assets/fonts/MonumentExtended/MonumentExtended-Light.woff2'
import monumentExtendedRegularWoff2 from 'src/assets/fonts/MonumentExtended/MonumentExtended-Regular.woff2'
import monumentExtendedUltralightWoff2 from 'src/assets/fonts/MonumentExtended/MonumentExtended-Ultralight.woff2'

interface FontStyle {
  woff: string
  woff2: string
  family: string
  weight: number
  style?: 'italic' | 'normal'
}

export const gilroy = 'Gilroy'
export const monumentExtended = 'MonumentExtended'

const generateFontFace = ({
  woff,
  woff2,
  family,
  weight,
  style = 'normal',
}: FontStyle) => `
  @font-face {
    font-display: swap;
    font-style: ${style};
    font-weight: ${weight};
    font-family: '${family}';
    src: url(${woff}) format('woff'), url(${woff2}) format('woff2');
  }
`

export const gilroyThin = generateFontFace({
  weight: 200,
  family: gilroy,
  woff: gilroyThinWoff,
  woff2: gilroyThinWoff2,
})

export const gilroyLight = generateFontFace({
  weight: 300,
  family: gilroy,
  woff: gilroyLightWoff,
  woff2: gilroyLightWoff2,
})

export const gilroyRegular = generateFontFace({
  weight: 400,
  family: gilroy,
  woff: gilroyRegularWoff,
  woff2: gilroyRegularWoff2,
})

export const gilroyMedium = generateFontFace({
  weight: 500,
  family: gilroy,
  woff: gilroyMediumWoff,
  woff2: gilroyMediumWoff2,
})

export const gilroyBold = generateFontFace({
  weight: 700,
  family: gilroy,
  woff: gilroyBoldWoff,
  woff2: gilroyBoldWoff2,
})

export const gilroyItalic = generateFontFace({
  weight: 400,
  family: gilroy,
  style: 'italic',
  woff: gilroyRegularItalicWoff,
  woff2: gilroyRegularItalicWoff2,
})

export const monumentExtendedUltralight = generateFontFace({
  weight: 200,
  family: monumentExtended,
  woff: monumentExtendedUltralightWoff,
  woff2: monumentExtendedUltralightWoff2,
})

export const monumentExtendedLight = generateFontFace({
  weight: 300,
  family: monumentExtended,
  woff: monumentExtendedLightWoff,
  woff2: monumentExtendedLightWoff2,
})

export const monumentExtendedRegular = generateFontFace({
  weight: 400,
  family: monumentExtended,
  woff: monumentExtendedRegularWoff,
  woff2: monumentExtendedRegularWoff2,
})

export const monumentExtendedBold = generateFontFace({
  weight: 700,
  family: monumentExtended,
  woff: monumentExtendedBoldWoff,
  woff2: monumentExtendedBoldWoff2,
})
