import css from '@emotion/css'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { Typography, Container as MuiContainer } from '@mui/material'

import Background from 'src/components/layout/Background'
import gap from 'src/utils/cssGap'

import shadow from 'src/assets/images/shadow.svg'
import ellipse from 'src/assets/images/ellipse.svg'
import { ReactComponent as Lines } from 'src/assets/images/fieldlines.svg'

const StyledBackground = styled(Background)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${gap.col(theme.spacing(4))}
    text-align: center;
  `
)

const Contents404 = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    > h1 {
      font-size: ${theme.typography.pxToRem(200)};
      line-height: ${theme.typography.pxToRem(200)};
    }

    ${theme.breakpoints.down('sm')} {
      > h1 {
        font-size: ${theme.typography.pxToRem(130)};
        line-height: ${theme.typography.pxToRem(130)};
      }
    }

    ${theme.breakpoints.down(400)} {
      > h1 {
        font-size: ${theme.typography.pxToRem(90)};
        line-height: ${theme.typography.pxToRem(90)};
      }
    }
  `
)

const FieldLines = styled(Lines)(
  ({ theme }) => css`
    top: 25%;
    left: 0px;
    position: absolute;

    > img {
      width: 100vw;
    }

    ${theme.breakpoints.down('sm')} {
      top: 40%;
    }

    ${theme.breakpoints.down('md')} {
      top: 35%;
    }

    ${theme.breakpoints.down('lg')} {
      top: 30%;
    }
  `
)

const EllipseWithShadow = styled.div(
  ({ theme }) => css`
    width: 175px;
    height: 175px;
    min-width: 175px;
    min-height: 175px;
    position: relative;
    margin: ${theme.spacing(0, 5)};

    > div:last-of-type {
      left: 0px;
      bottom: 0px;
      width: 100%;
      position: absolute;
    }

    > div:first-of-type {
      left: 0;
      width: 100%;
      bottom: -10%;
      position: absolute;

      img {
        width: 80%;
      }
    }

    ${theme.breakpoints.down('sm')} {
      width: 125px;
      height: 125px;
      min-width: 125px;
      min-height: 125px;
      margin: ${theme.spacing(0, 2)};
    }

    ${theme.breakpoints.down(400)} {
      width: 90px;
      height: 90px;
      min-width: 90px;
      min-height: 90px;
      margin: ${theme.spacing(0, 1)};
    }
  `
)

const NotFound = () => {
  return (
    <StyledBackground>
      <FieldLines />
      <Container>
        <Contents404>
          <Typography variant="h1">4</Typography>
          <EllipseWithShadow>
            <motion.div
              transition={{
                delay: 0.1,
                duration: 0.6,
              }}
              animate={{ scale: 1 }}
              initial={{ scale: 0.4 }}
            >
              <img src={shadow} alt="shadow" />
            </motion.div>
            <motion.div
              transition={{
                delay: 0.1,
                duration: 0.6,
              }}
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: -125, opacity: 0 }}
            >
              <img src={ellipse} alt="ellipse" />
            </motion.div>
          </EllipseWithShadow>
          <Typography variant="h1">4</Typography>
        </Contents404>
        <Typography variant="h3" color="textSecondary">
          ERROR, PAGE NOT FOUND
        </Typography>
      </Container>
    </StyledBackground>
  )
}

export default NotFound
