import css from '@emotion/css'
import styled from '@emotion/styled'

import { Link } from 'react-router-dom'

import { Routes } from 'src/utils/routes'

import shield from 'src/assets/images/shield-logo.svg'

const Header = styled.header(
  ({ theme }) => css`
    z-index: 1;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 65px;
    }
    ${theme.breakpoints.down('sm')} {
      height: 66px;
      margin: ${theme.spacing(2.5, 0, 4)};
      img {
        width: 50px;
      }
    }
  `
)

const SiteTeaseHeader: React.FC = () => {
  return (
    <Header>
      <Link to={Routes.Home}>
        <img src={shield} alt="AIFA Shield logo" />
      </Link>
    </Header>
  )
}

export default SiteTeaseHeader
