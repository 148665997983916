export enum Routes {
  Home = '/',
  About = '/about',
  Purchase = '/purchase',
  Dashboard = '/dashboard',
  Configure = '/configure',
}

export enum DashboardRoutes {
  Form = '/form/:tokenId',
  Brain = '/brain/:tokenId',
}
