import css from '@emotion/css'
import styled from '@emotion/styled'

const Background = styled.div(
  ({ theme }) => css`
    flex: 1;
    background: ${theme.palette.gradients.light};

    /* navbar offset */
    margin-top: ${theme.spacing(-12)} !important;
    padding-top: ${theme.spacing(12)} !important;

    ${theme.breakpoints.down('sm')} {
      /* navbar offset */
      /* TODO: Remove after box drop date */
      margin-top: ${theme.spacing(-15)} !important;
      padding-top: ${theme.spacing(15)} !important;
    }
  `
)

export default Background
