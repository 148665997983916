import { isLeft } from 'fp-ts/Either'
import { useState, useEffect, createContext } from 'react'

import { api } from 'src/utils/api'
import { useToast } from 'src/hooks/store/useToast'
import { ContractState, GetStatusResponseC } from 'src/models/api'

export const ContractStatusContext = createContext({
  status: ContractState.Pending,
  initialised: false,
})

const DELAY = 15000

const ContractStatusProvider: React.FC = ({ children }) => {
  const [status, setStatus] = useState(ContractState.Pending)
  const [initialised, setInitialised] = useState(false)

  const { openToast } = useToast()

  const getCurrentStatus = () => {
    api
      .get('get-status')
      .then(response => {
        const decodedResponse = GetStatusResponseC.decode(response.data)

        if (isLeft(decodedResponse)) {
          openToast({
            severity: 'error',
            message: 'Could not get sale status, please refresh the page',
          })
          return
        }

        setStatus(decodedResponse.right)
      })
      .catch(() => {
        openToast({
          severity: 'error',
          message: 'Could not get sale status, please refresh the page',
        })
      })
      .finally(() => {
        if (!initialised) setInitialised(true)
      })
  }

  // Set initial status from contract
  useEffect(() => {
    getCurrentStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Poll status from API
  useEffect(() => {
    const interval = setInterval(getCurrentStatus, DELAY)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContractStatusContext.Provider value={{ status, initialised }}>
      {children}
    </ContractStatusContext.Provider>
  )
}

export default ContractStatusProvider
