import { pipe } from 'fp-ts/lib/function'
import * as Codec from 'io-ts/Codec'
import * as Decoder from 'io-ts/Decoder'
import * as Encoder from 'io-ts/Encoder'

// Request
export const GetSignedTokenRequestC = Codec.struct({
  walletAddress: Codec.string,
})
export type GetSignedTokenRequest = Codec.TypeOf<typeof GetSignedTokenRequestC>
// Response
export enum ContractState {
  Pending = 'Pending',
  PreSale = 'PreSale',
  Public = 'Public',
  Finished = 'Finished',
}
const SignedTokenC = Codec.struct({
  signedToken: Codec.string,
  salt: Codec.string,
})
const PreSaleTokenResultC = Codec.sum('type')({
  error: Codec.struct({
    type: Codec.literal('error'),
    error: Codec.literal('NotInWhiteList'),
  }),
  success: Codec.intersect(Codec.struct({ type: Codec.literal('success') }))(
    SignedTokenC
  ),
})
export const GetSignedTokenResponseC = Codec.sum('status')({
  [ContractState.Pending]: Codec.struct({
    status: Codec.literal(ContractState.Pending),
  }),
  [ContractState.PreSale]: Codec.struct({
    status: Codec.literal(ContractState.PreSale),
    result: PreSaleTokenResultC,
  }),
  [ContractState.Public]: Codec.struct({
    status: Codec.literal(ContractState.Public),
    result: SignedTokenC,
  }),
  [ContractState.Finished]: Codec.struct({
    status: Codec.literal(ContractState.Finished),
  }),
})

export type GetSignedTokenResponse = Codec.TypeOf<
  typeof GetSignedTokenResponseC
>

export const GetStatusResponseC = Codec.make(
  pipe(
    Decoder.string,
    Decoder.parse(str => {
      const state = Object.values(ContractState).find(s => s === str)
      if (!state) {
        return Decoder.failure(str, 'Not a valid state')
      }

      return Decoder.success(state)
    })
  ),
  Encoder.id<ContractState>()
)

export type GetStatusResponse = Codec.TypeOf<typeof SignedTokenC>
