import css from '@emotion/css'
import styled from '@emotion/styled'
import { useRef, useLayoutEffect } from 'react'
import { motion, useCycle, Variants } from 'framer-motion'

import { Container } from '@mui/material'

import Account from 'src/modules/header/Account'
import { HeaderLink } from 'src/modules/header/HeaderLink'
import BurgerIcon from 'src/modules/header/burger/BurgerIcon'

interface Route {
  label: string
  route: string
  exact: boolean
}

interface BurgerMenuProps {
  routes: Route[]
  isSmallScreen: boolean
}

const BurgerContainer = styled(motion.div)`
  margin-left: auto;
  position: relative;
`

const Backdrop = styled(motion.div)(
  ({ theme }) => css`
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    position: fixed;
    padding: ${theme.spacing(10, 0, 0)};
    background: ${theme.palette.gradients.moody};
  `
)

const ItemsContainer = styled.nav(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: ${theme.spacing(5, 0, 0)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(3, 0, 0)};
    }
  `
)

const Item = styled(motion.div)`
  display: flex;
`

const transition = {
  damping: 40,
  type: 'spring',
  stiffness: 200,
}

const backdropVariants: Variants = {
  open: {
    opacity: 1,
    pointerEvents: 'all',
    transition,
  },
  closed: {
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      delay: 0.3,
      ...transition,
    },
  },
}

const itemVariants: Variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 30,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const body = document.querySelector('body')

const BurgerMenu: React.FC<BurgerMenuProps> = ({ routes, isSmallScreen }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isBurgerOpen, toggleIsBurgerOpen] = useCycle(false, true)

  const handleMenuClick = () => {
    toggleIsBurgerOpen()
  }

  // Prevent scrolling of page when Menu is open
  useLayoutEffect(() => {
    body!.style.overflow = isBurgerOpen ? 'hidden' : 'auto'

    // Clean up body style in edge case where burger menu is removed from the tree by resizing the window
    return () => {
      body!.style.overflow = 'auto'
    }
  }, [isBurgerOpen])

  return (
    <BurgerContainer
      ref={ref}
      initial={false}
      transition={{ duration: 300 }}
      animate={isBurgerOpen ? 'open' : 'closed'}
    >
      <BurgerIcon burgerToggle={toggleIsBurgerOpen} />
      <Backdrop variants={backdropVariants} initial={false}>
        <Container>
          <ItemsContainer>
            {routes.map(({ exact, route, label }) => (
              <Item key={route} variants={itemVariants}>
                <HeaderLink
                  to={route}
                  exact={exact}
                  onClick={handleMenuClick}
                  activeClassName="active-link"
                >
                  {label}
                </HeaderLink>
              </Item>
            ))}
            {isSmallScreen && (
              <Item variants={itemVariants}>
                <Account onConnectClick={toggleIsBurgerOpen} />
              </Item>
            )}
          </ItemsContainer>
        </Container>
      </Backdrop>
    </BurgerContainer>
  )
}

export default BurgerMenu
