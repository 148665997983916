import styled from '@emotion/styled'
import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Header from 'src/modules/header'
import { Routes } from 'src/utils/routes'
import Footer from 'src/modules/_core/Footer'
import NotFound from 'src/modules/_core/NotFound'
import Toast from 'src/components/feedback/Toast'
import PageLoading from 'src/modules/_core/PageLoading'
import SkipLink from 'src/components/navigation/SkipLink'
import { useEagerConnect } from 'src/hooks/useEagerConnect'
import ConnectWalletDialog from 'src/modules/_core/ConnectWalletDialog'
import { useAifaAllstarsStatus } from 'src/hooks/smart-contracts/useAifaAllstarsBoxStatus'

import SiteTeaseHeader from 'src/modules/header/SiteTeaseHeader'

const Home = lazy(() => import('src/modules/home'))
const About = lazy(() => import('src/modules/about'))
const Purchase = lazy(() => import('src/modules/purchase'))
// const Dashboard = lazy(() => import('src/modules/dashboard'))
// const Configure = lazy(() => import('src/modules/dashboard/configure'))

const Layout = styled.div`
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column;
`

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ContractStateSuspense: React.FC = ({ children }) => {
  const { initialised } = useAifaAllstarsStatus()
  return <>{initialised ? children : <PageLoading />}</>
}

const App: React.FC = () => {
  useEagerConnect()
  const { status } = useAifaAllstarsStatus()
  const isPending = status === 'Pending'

  return (
    <Router>
      <SkipLink href="#main-pass">Skip to main content</SkipLink>
      <Layout>
        <Suspense fallback={<PageLoading />}>
          <ContractStateSuspense>
            {!isPending ? <Header /> : <SiteTeaseHeader />}
            <Main id="main-pass">
              <Switch>
                <Route path={Routes.Home} exact>
                  <Home />
                </Route>
                <Route path={Routes.About} exact>
                  <About />
                </Route>
                {!isPending && (
                  <Route path={Routes.Purchase} exact>
                    <Purchase status={status} />
                  </Route>
                )}
                {/* TODO: restore at phase 2 */}
                {/* <Route path={Routes.Dashboard}>
                <Dashboard />
              </Route> */}
                {/* <Route path={Routes.Configure} exact>
                <Configure />
              </Route> */}
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </Main>
          </ContractStateSuspense>
        </Suspense>
        <Footer />
      </Layout>
      <Toast />
      <ConnectWalletDialog />
    </Router>
  )
}

export default App
