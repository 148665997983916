import { useTheme, useMediaQuery } from '@mui/material'

import { BURGER_MENU_BREAKPOINT } from 'src/utils/constants'

export const useBreakpoints = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const showBurgerMenu = useMediaQuery(
    theme.breakpoints.down(BURGER_MENU_BREAKPOINT)
  )

  return { theme, useMediaQuery, showBurgerMenu, isSmallScreen }
}
