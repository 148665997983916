import css from '@emotion/css'
import styled from '@emotion/styled'

import {
  ButtonBase,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material'

import { useWallet } from 'src/hooks/useWallet'
import Dialog from 'src/components/feedback/Dialog'
import { connectors, ConnectorNames } from 'src/utils/web3'
import { useWalletsDialog } from 'src/hooks/store/useWalletDialog'

import coinbaseSrc from 'src/assets/images/coinbase-logo.png'
import metamaskSrc from 'src/assets/images/metamask-logo.png'
import walletConnectSrc from 'src/assets/images/walletconnect-logo.png'

const Description = styled(Typography)`
  font-weight: 500;
`

const ProvidersContainer = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      justify-content: center;
      margin: ${theme.spacing(4, 0, 0)};
      margin-left: auto;
      margin-right: auto;

      ${theme.breakpoints.down('md')} {
        align-items: center;
        flex-direction: column;
      }
    `
)

const Button = styled(ButtonBase)(
  ({ theme }) => css`
    width: 280px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    background-color: #fff;
    padding: ${theme.spacing(3)};
    margin: ${theme.spacing(0, 4, 0, 0)};
    font-size: ${theme.typography.pxToRem(24)};
    color: ${theme.palette.background.darkTeal};
    line-height: ${theme.typography.pxToRem(32)};

    &:last-child {
      margin: 0;
    }

    img {
      width: 28px;
      margin-right: ${theme.spacing(2)};
    }

    ${theme.breakpoints.down('md')} {
      margin: ${theme.spacing(0, 0, 3)};
    }

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(18)};
      line-height: ${theme.typography.pxToRem(24)};
    }

    ${theme.breakpoints.down(360)} {
      width: 100%;
    }
  `
)

const connectorImageMap = {
  [ConnectorNames.Coinbase]: coinbaseSrc,
  [ConnectorNames.MetaMask]: metamaskSrc,
  [ConnectorNames.WalletConnect]: walletConnectSrc,
}

const ConnectWalletDialog: React.FC = () => {
  const { connectWallet } = useWallet()
  const { isWalletsDialogOpen, closeWalletsDialog } = useWalletsDialog()

  const generateConnectWalletHandler =
    (connectorName: ConnectorNames) => () => {
      closeWalletsDialog()
      connectWallet(connectorName)
    }

  return (
    <Dialog open={isWalletsDialogOpen} onClose={closeWalletsDialog}>
      <DialogTitle>
        <Typography variant="h3" align="center" component="span">
          Connect your wallet
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Description align="center" variant="body2">
          You need an Ethereum wallet to purchase an AIFA Original.
        </Description>
        <ProvidersContainer>
          {Object.keys(connectors).map(key => (
            <Button
              key={key}
              onClick={generateConnectWalletHandler(key as ConnectorNames)}
            >
              <img
                src={connectorImageMap[key as ConnectorNames]}
                alt={`${key} logo`}
              />
              {key}
            </Button>
          ))}
        </ProvidersContainer>
      </DialogContent>
    </Dialog>
  )
}

export default ConnectWalletDialog
