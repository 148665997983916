import axios, { AxiosRequestConfig } from 'axios'

export const baseURL = process.env.REACT_APP_API_ENDPOINT

export const defaultConfig: AxiosRequestConfig = {
  baseURL,
  timeout: 10000,
  responseType: 'json',
}

export const api = axios.create(defaultConfig)
