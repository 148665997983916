import { useEffect } from 'react'

import { useWallet } from 'src/hooks/useWallet'
import { getConnectorName } from 'src/utils/web3'

// If there is an existing connection to a wallet then eagerly connect to it
export const useEagerConnect = () => {
  const { connectWallet } = useWallet()

  useEffect(() => {
    const connectorName = getConnectorName()

    if (connectorName) {
      connectWallet(connectorName)
    }
  }, [connectWallet])
}
