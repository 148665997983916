import { Web3ReactProvider } from '@web3-react/core'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider as EmotionProvider } from '@emotion/react'

import { CssBaseline } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import theme from 'src/styles/theme'
import { getLibrary } from 'src/utils/web3'
import GlobalStyles from 'src/styles/GlobalStyles'
import ContractStatusProvider from 'src/modules/_core/ContractStatusContext'

const queryClient = new QueryClient()

const Providers: React.FC = ({ children }) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <EmotionProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <GlobalStyles />
            <CssBaseline />
            <ContractStatusProvider>{children}</ContractStatusProvider>
          </StyledEngineProvider>
        </EmotionProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </Web3ReactProvider>
)

export default Providers
